<template>
  <div id="serviceDetail">
    <div class="goods-box">
      <div class="title">退款商品</div>
      <div class="goods-item">
        <div class="item" v-for="(item, index) in infoData.ProductList" :key="index">
          <img v-lazy="item.product_cover" alt/>
          <div>
            <p>{{item.product_name}}</p>
            <span>x{{item.product_num}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="reason-box" v-if="!showImg">
      <div class="reason-top">
        <p>退款原因</p>
        <span>{{infoData.refund_reason}}</span>
      </div>

      <div class="reason-inner" v-if="infoData.specific_reason.length > 0">{{infoData.specific_reason}}</div>
      <div class="reason-pic">
        <van-image
          fit="fill"
          @click="showPic(index)"
          :key="index"
          v-for="(item, index) in infoData.refund_imgs"
          width="19.2* 3.75px"
          height="19.2* 3.75px"
          :src="item"
        >
          <template v-slot:loading>
            <van-loading type="spinner" size="20"/>
          </template>
        </van-image>
      </div>
    </div>

    <div class="position-box" v-if="!showImg">
      <div class="position-left">
        <div class="left-top">
          <p>￥{{infoData.product_total | fixdTwo}}</p>
          <span>（部分退款配送费不退回）</span>
        </div>
        <div class="left-bot">退款将按原路返回</div>
      </div>

      <div class="position-right" v-if="infoData.refund_status == 0">
        <div class="btn" @click="handle(2)">拒绝</div>
        <div class="btn active" @click="handle(1)">同意</div>
      </div>

      <div class="position-right" v-if="infoData.refund_status == 1">
        <div class="btn">已同意</div>
      </div>

      <div class="position-right" v-if="infoData.refund_status == 2">
        <div class="btn">已拒绝</div>
      </div>
    </div>

    <!-- <big-img v-if="showImg" @clickit="viewImg" :imgSrc="imgSrc"></big-img> -->
  </div>
</template>

<script>
  import {Dialog, ImagePreview, Toast} from "vant";
  import BigImg from "../components/img";
  import {refundDetail, refundOperate} from "../api/requist";

  export default {
    components: {
      "big-img": BigImg
    },
    filters: {
      fixdTwo: function (val) {
        return Math.round(val * 100) / 100
      }
    },
    props: [],
    data() {
      return {
        imgSrc: "",
        showImg: false,
        infoData: {}
      };
    },
    created() {
    },
    mounted() {
      this.getInfo();
    },
    watch: {},
    computed: {},
    methods: {
      showPic(i) {
        ImagePreview({
          images: [...this.infoData.refund_imgs],
          startPosition: i,
          onClose() {
            // do something
          }
        });
      },
      clickImg(e) {
        this.showImg = true;
        this.imgSrc = e.currentTarget.src;
      },
      viewImg() {
        this.showImg = false;
      },
      async getInfo() {
        let formData = new FormData();
        formData.append("merch_id", localStorage.getItem("merch_id"));
        formData.append("refund_id", this.$route.query.id);
        let res = await refundDetail(formData);
        if (res.code === 200) {
          this.infoData = res.data;
          this.infoData.refund_imgs = JSON.parse(this.infoData.refund_imgs);
        }
      },

      async handle(type) {
        let that = this;
        if (type === 1) {
          Dialog.confirm({
            message: "同意后货款将原路返回买家账户，确认继续退款？",
            confirmButtonText: "确认",
            confirmButtonColor: "#FF6737",
            cancelButtonText: "取消",
            cancelButtonColor: "#909399"
          })
            .then(() => {
              let formData = new FormData();
              formData.append("merch_id", localStorage.getItem("merch_id"));
              formData.append("refund_id", that.$route.query.id);
              formData.append("refund_status", type);
              refundOperate(formData).then(res => {
                that.infoData.refund_status = type;
                Toast(res.message);
              });

              // on confirm
            })
            .catch(() => {
              // on cancel
            });
        } else {
          Dialog.confirm({
            message: "确认要拒绝买家的退款申请吗？",
            confirmButtonText: "取消",
            confirmButtonColor: "#FF6737",
            cancelButtonText: "确认",
            cancelButtonColor: "#909399"
          })
            .then(() => {
            })
            .catch(() => {
              let formData = new FormData();
              formData.append("merch_id", localStorage.getItem("merch_id"));
              formData.append("refund_id", that.$route.query.id);
              formData.append("refund_status", type);
              refundOperate(formData).then(res => {
                that.infoData.refund_status = type;
                Toast(res.message);
              });
            });
        }
      }
    }
  };
</script>
<style lang="scss">
  @import "../common/css/theme.scss";

  #serviceDetail {
    padding-top: 3.2* 3.75px;
    padding-bottom: 13.87* 3.75px;
    box-sizing: border-box;
    position: relative;
    min-height: 100vh;
    background: rgba(245, 247, 250, 1);

    .goods-box {
      margin: 0* 3.75px auto 4.27* 3.75px;
      width: 93.6* 3.75px;
      padding: 5.33* 3.75px 3.2* 3.75px 9.07* 3.75px;
      box-sizing: border-box;
      background: rgba(255, 255, 255, 1);
      border-radius: 1* 3.75px;

      .title {
        margin-bottom: 8.53* 3.75px;
        text-align: left;
        font-weight: bold;
        color: rgba(48, 49, 51, 1);
        font-size: 3.73* 3.75px;
      }

      .goods-item {
        .item {
          margin-bottom: 8.53* 3.75px;
          display: flex;
          justify-content: space-between;

          &:last-child {
            margin-bottom: 0;
          }

          img {
            height: 16.8* 3.75px;
            width: 16.8* 3.75px;
          }

          p {
            text-align: left;
            width: 68.27* 3.75px;
            height: 10.67* 3.75px;
            font-size: 3.73* 3.75px;
            line-height: 5.33* 3.75px;
            color: rgba(48, 49, 51, 1);
            display: -webkit-box;
            overflow: hidden;
            white-space: normal !important;
            text-overflow: ellipsis;
            word-wrap: break-word;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          span {
            display: block;
            color: rgba(144, 147, 153, 1);
            font-size: 3.73* 3.75px;
            text-align: right;
          }
        }
      }
    }

    .reason-box {
      background-color: #fff;
      width: 93.6* 3.75px;
      padding: 4.27* 3.75px 3.2* 3.75px;
      box-sizing: border-box;
      margin: 4.27* 3.75px auto;

      .reason-top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          font-weight: bold;
          color: rgba(48, 49, 51, 1);
          font-size: 3.73* 3.75px;
        }

        span {
          color: rgba(48, 49, 51, 1);
          font-size: 3.73* 3.75px;
        }
      }

      .reason-inner {
        margin: 16px 0 0;
        width: 87.2* 3.75px;
        font-size: 3.73* 3.75px;
        line-height: 5.33* 3.75px;
        color: rgba(48, 49, 51, 1);
        text-align: left;
      }

      .reason-pic {
        display: flex;

        img {
          border-radius: 4px;
          margin-right: 2.13* 3.75px;
          height: 19.2* 3.75px;
          width: 19.2* 3.75px;
        }

        .van-image__loading {
          border-radius: 4px;
        }

        .van-image {
          margin-right: 2.13* 3.75px;
        }
      }
    }

    .position-box {
      width: 100%;
      height: 13.87* 3.75px;
      background: rgba(255, 255, 255, 1);
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 2.13* 3.75px 4.27* 3.75px 2.13* 3.75px 3.2* 3.75px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .position-left {
        .left-top {
          margin-bottom: 0.27* 3.75px;
          display: flex;
          align-items: center;

          p {
            font-weight: bold;
            color: rgba(255, 0, 0, 1);
            font-size: 4.27* 3.75px;
          }

          span {
            color: rgba(96, 98, 102, 1);
            font-size: 3.73* 3.75px;
          }
        }

        .left-bot {
          color: rgba(144, 147, 153, 1);
          font-size: 2.67* 3.75px;
          text-align: left;
        }
      }

      .position-right {
        display: flex;
        align-items: center;

        .btn {
          font-size: 3.73* 3.75px;
          color: rgba(144, 147, 153, 1);

          &.active {
            font-size: 3.73* 3.75px;
            color: $theme-color;
          }

          &:last-child {
            margin-left: 5.33* 3.75px;
          }
        }
      }
    }
  }
</style>
